


































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { TalentListObj, TalentAddParams } from './types'
import { Form } from 'element-ui'
import { formatTimestamp } from '@/utils/utils'
@Component({ name: 'TalentList' })
export default class TalentList extends VueBase {
  private page = 1
  private pageSize = 20
  private total = 0
  private keywords = ''
  private tableData: Array<TalentListObj> = []
  private addDialogOpen = false
  private isEdit = false
  private talentForm: TalentAddParams = {
    talent_name: '',
    is_active: true,
    id: 0,
  }
  created() {
    this.getTableData()
  }

  private fmtStr(timestamp: number | any) {
    return formatTimestamp(timestamp)
  }
  private addDialogShow() {
    this.talentForm = {
      talent_name: '',
      is_active: true,
    }
    this.addDialogOpen = true
    this.isEdit = false
  }

  private talentEdit(row: TalentListObj) {
    this.talentForm = {
      talent_name: row.talent_name,
      is_active: row.is_active,
      id: row.id,
    }
    this.addDialogOpen = true
    this.isEdit = true
  }

  private cancelAdd() {
    this.addDialogOpen = false
    this.isEdit = false
  }
  private newSelectData() {
    this.page = 1
    this.getTableData()
  }
  private currentChange(val: number) {
    this.page = val
    this.getTableData()
  }
  private async getTableData() {
    const params: {
      page: number
      pageSize: number
      name?: string
    } = {
      page: this.page,
      pageSize: this.pageSize,
    }
    if (this.keywords) {
      params.name = this.keywords
    }
    this.loadingStart()
    const { status, msg, data, total } = await this.services.talent.talentList(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({ type: 'error', message: msg, showClose: true })
      return
    }
    this.tableData = data
    this.total = total
  }

  private async talentAdd() {
    this.loadingStart()
    if (this.isEdit) {
      const params: TalentAddParams = {
        talent_name: this.talentForm.talent_name,
        is_active: this.talentForm.is_active,
        id: this.talentForm.id,
      }
      const { status, msg } = await this.services.talent.talentEdit(params)
      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
    } else {
      const params: TalentAddParams = {
        talent_name: this.talentForm.talent_name,
        email: this.talentForm.email,
        is_active: this.talentForm.is_active,
      }
      const { status, msg } = await this.services.talent.talentAdd(params)
      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
    }

    this.addDialogOpen = false
    this.getTableData()
  }

  private async talentDelete(id: number) {
    this.$confirm(this.$t('views.talent.delete.confirm') as string, {
      confirmButtonText: this.$t('views.talent.delete.confirmBtn') as string,
      cancelButtonText: this.$t('views.talent.delete.cancelBtn') as string,
      type: 'warning',
      center: true,
    }).then(async () => {
      this.loadingStart()
      const { status, msg } = await this.services.talent.talentDelete(id)
      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      this.$message({
        type: 'success',
        message: msg,
        showClose: true,
      })
      this.getTableData()
    })
  }
}
